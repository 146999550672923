<template>
  <b-dropdown
    size="sm"
    variant="button"
    toggle-class="custom-v-dropdown btn btn-light btn-sm btn-icon text-primary"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-hor text-primary"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-250px">
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" @click.prevent="openModal">
          <span class="navi-icon">
            <i class="flaticon2-open-text-book"></i>
          </span>
          <span class="navi-text">Topics</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <router-link :to="`/brands/${cusId}/competitors`" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-group"></i>
          </span>
          <span class="navi-text">Competitors</span>
        </router-link>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<script>
export default {
  name: "BrandAction",
  props: {
    cusId: {
      type: String,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$emit("show-topics")
      this.$nextTick(() => {
        this.$bvModal.show("brand-topics")
      })
    },
  },
}
</script>

<style></style>
