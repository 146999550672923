<template>
  <div>
    <div class="row mt-5">
      <div class="col-12">
        <KTCard>
          <template v-slot:title>
            <h6 class="mb-0">Brand Configuration</h6>
          </template>
          <template v-slot:toolbar>
            <button-add v-b-modal.brand-create></button-add>
          </template>
          <template v-slot:body>
            <b-table
              bordered
              responsive
              :items="brandDatas"
              :fields="fields"
              show-empty
              :busy="!isLoaded"
            >
              <template v-slot:table-busy>
                <div class="text-danger my-2 text-center">
                  <b-spinner class="align-middle mx-2"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:empty>
                <center>No data</center>
              </template>

              <template v-slot:cell(no)="row">
                {{ iteration + row.index + 1 }}
              </template>
              <template v-slot:cell(channel)="row">
                <p v-for="(line, i) of row.item.reportWay.lines" :key="i">
                  {{ line.text }}
                </p>
              </template>
              <template v-slot:cell(numberOfTopics)="row">
                {{ row.item.topics.length }}
              </template>

              <template v-slot:cell(actions)="row">
                <button-edit
                  @click="selectedBrand = row.item"
                  v-b-modal.brand-edit
                >
                </button-edit>

                <button-delete
                  v-b-modal.delete-brand
                  @click="selectedBrand = row.item"
                ></button-delete>

                <brand-action
                  @show-topics="selectedBrand = row.item"
                  :cus-id="row.item.cusId"
                ></brand-action>
              </template>
            </b-table>
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <span v-if="paging"
                >{{ paging.totalRows | number_format }} records</span
              >
              <b-pagination-nav
                v-if="paging.numberOfPages"
                v-model="paging.currentPage"
                :total-rows="paging.totalRows"
                align="right"
                :link-gen="linkGen"
                :number-of-pages="paging.numberOfPages"
                use-router
                :per-page="paging.perPage"
                @change="changePage"
              ></b-pagination-nav>
            </div>
          </template>
        </KTCard>
      </div>
    </div>

    <b-modal
      scrollable
      hide-footer
      size="md"
      id="brand-topics"
      :title="`Topic of ${selectedBrand.name}`"
      v-if="selectedBrand"
    >
      <p v-for="(topic, i) of selectedBrand.topics" :key="i">
        {{ i + 1 }}. {{ topic.name }}
      </p>
    </b-modal>

    <BrandCreate
      :lines="lines"
      :media-influencers="mediaInfluencers"
      @success="getBrands"
    ></BrandCreate>

    <BrandEdit
      v-if="selectedBrand"
      :lines="lines"
      :media-influencers="mediaInfluencers"
      :item="selectedBrand"
      @success="getBrands"
      @hidden="selectedBrand = null"
    ></BrandEdit>

    <confirm-delete
      v-if="selectedBrand"
      modal-id="delete-brand"
      :remove-fn="removeBrand"
      @ok="getBrands"
    ></confirm-delete>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { CustomerService, UtilsService, TopicService } from "@/core/services"
import BrandCreate from "./BrandCreate"
import BrandEdit from "./BrandEdit"
import KTCard from "@/view/content/Card.vue"
import BrandAction from "@/view/content/custom/BrandAction.vue"

export default {
  name: "brand",
  components: {
    BrandCreate,
    BrandEdit,
    KTCard,
    BrandAction,
  },
  data() {
    return {
      isLoaded: false,
      paging: {
        perPage: 10,
        currentPage: 1,
        totalRows: 0,
        numberOfPages: 0,
      },
      selectedBrand: null,
      brandDatas: [],
      brands: [],
      showBrand: null,
      fields: [
        {
          key: "no",
          label: "#",
          class: "text-center",
        },
        {
          key: "name",
          label: "Brand Name",
        },
        {
          key: "channel",
          label: "Report Channel",
        },
        {
          key: "numberOfTopics",
          label: "Number of topics",
          class: "text-right",
        },
        {
          key: "actions",
          label: "Action",
          class: "text-center",
        },
      ],
      topics: [],
      lines: [],
      mediaInfluencers: [],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Brands" }])

    UtilsService.getLines().then((lines) => {
      this.lines = lines.map((v) => {
        return {
          value: v.groupId,
          text: v.groupName,
        }
      })
    })

    UtilsService.getMediaInfluencers().then((mediaInfluencers) => {
      this.mediaInfluencers = mediaInfluencers.map((v) => {
        return {
          value: v.mediaId,
          label: v.name,
        }
      })
    })

    this.paging.currentPage = Number(this.$route.query.page) || 1
    this.getBrands()
  },
  computed: {
    iteration() {
      return this.paging.perPage * (this.paging.currentPage - 1)
    },
  },
  methods: {
    changePage(page) {
      this.paging.currentPage = page
      this.getBrands()
    },
    getBrands() {
      this.selectedBrand = null
      this.isLoaded = false
      CustomerService.get({
        offset: this.paging.perPage * (this.paging.currentPage - 1),
        limit: this.paging.perPage,
      }).then((customers) => {
        this.isLoaded = true
        let brands = customers.results || []
        this.paging.totalRows = customers.total
        this.paging.numberOfPages = Math.ceil(
          this.paging.totalRows / this.paging.perPage
        )
        this.brandDatas = brands.map((b) => {
          return {
            ...b,
            reportChannel: b.lines,
            topics: b.topics.filter((v) => v.name),
          }
        })
      })
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`
    },
    removeBrand() {
      return CustomerService.delete(this.selectedBrand.cusId)
    },
    async getTopics(selectedBrand) {
      this.selectedBrand = selectedBrand
      let data = await TopicService.get({
        limit: 0,
        offset: 0,
        cusId: this.selectedBrand.cusId,
      })
      this.topics = data.results
    },
  },
}
</script>
