<template>
  <b-modal
    size="md"
    id="brand-edit"
    title="Edit Brand"
    ok-title="Save"
    @ok="submit"
    @hidden="$emit('hidden')"
    :ok-disabled="isProcessing"
  >
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="name">Brand Name <span class="text-danger">*</span></label>
      </div>
      <div class="col-sm-9">
        <b-form-input v-model="form.name" id="name"></b-form-input>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="brandColor"
          >Brand Color <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-input
          type="color"
          v-model="form.brandColor"
          id="brandColor"
        ></b-form-input>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="statuses">Statuses </label>
      </div>
      <div class="col-sm-9">
        <b-form-textarea
          @change="onChangeStatus"
          placeholder="Enter something..."
          rows="3"
          v-model="statusesText"
          id="statuses"
        ></b-form-textarea>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="intention"
          >Intention <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-textarea
          @change="onChangeIntention"
          placeholder="Enter something..."
          rows="3"
          v-model="intentionText"
          id="intention"
        ></b-form-textarea>
      </div>
    </div>

    <!-- <div class="form-group row">
      <div class="col-sm-3">
        <label for="mediaList">Media/Influencer</label>
      </div>
      <div class="col-sm-9">
        <multiselect
          v-model="form.mediaList"
          :multiple="true"
          :options="mediaInfluencers"
          :searchable="true"
          :close-on-select="false"
          :show-labels="true"
          :hide-selected="false"
          placeholder="Pick a value"
          :limit="3"
          :options-limit="300"
          track-by="value"
          label="label"
        ></multiselect>
      </div>
    </div> -->

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="start_end"
          >Line Alert Start/End Time <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-input type="time" v-model="form.startTime"></b-form-input>
        <b-form-input type="time" v-model="form.endTime"></b-form-input>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="reportWay"
          >Report Channel <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <multiselect
          :options="lines"
          multiple
          v-model="form.reportWay.lines"
          track-by="value"
          label="text"
          id="reportWay"
        ></multiselect>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="cronjob"
          ><span>Line Notify Time </span><span class="text-danger">*</span
          ><br /><span class="text-muted">Ex. 23:00</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-tags input-id="keywords" v-model="form.cronJob"></b-form-tags>
        <!-- <multiselect
          v-model="form.cronJob"
          :multiple="true"
          :options="lineNotifyOptions"
          :searchable="true"
          :close-on-select="false"
          :show-labels="true"
          :hide-selected="false"
          placeholder="Search or add time"
          :limit="3"
          :options-limit="300"
          :taggable="true"
          id="cronjob"
          @tag="addLineNotifyTime"
          @remove="onRemoveLineNotifyOpts"
        ></multiselect> -->
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { CustomerService } from "../../../core/services";
import moment from "moment";

export default {
  name: "BrandEdit",
  props: {
    lines: {
      type: Array,
      default: () => []
    },
    mediaInfluencers: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        name: null,
        brandColor: null,
        reportWay: {
          lines: []
        },
        statuses: [],
        intention: [],
        startTime: null,
        endTime: null,
        mediaList: [],
        cronJob: []
      },
      // competitorName: "",
      // competitorColor: "#000000",
      intentionText: "",
      statusesText: "",

      isProcessing: false
    };
  },
  validations: {
    form: {
      name: { required },
      brandColor: { required },
      reportWay: {
        lines: {
          required
        }
      },
      // statuses: { required },
      intention: { required },
      startTime: { required },
      endTime: { required },
      cronJob: { required }
    }
  },
  mounted() {
    let formData = JSON.parse(JSON.stringify(this.item));
    formData.mediaList = this.mediaInfluencers.filter(v =>
      this.item.mediaList.includes(v.value)
    );

    delete formData.topics;

    this.form = formData;
    this.form.startTime = moment(this.form.startTime, "HH:mm").format("HH:mm");
    this.form.endTime = moment(this.form.endTime, "HH:mm").format("HH:mm");

    this.intentionText = this.form.intention.join("\n");
    this.statusesText = this.form.statuses.join("\n");
  },
  methods: {
    onChangeIntention() {
      this.form.intention = this.intentionText.split(/\n/, -1).filter(v => v);
      this.intentionText = this.intentionText.replace(/\n$/, "");
    },
    onChangeStatus() {
      this.form.statuses = this.statusesText.split(/\n/, -1).filter(v => v);
      this.statusesText = this.statusesText.replace(/\n$/, "");
    },
    async submit(e) {
      e.preventDefault();
      this.isProcessing = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.isProcessing = false;
        return;
      }
      let data = JSON.parse(JSON.stringify(this.form));
      data.mediaList = data.mediaList.map(v => v.value);
      delete data.topics;
      let response = await CustomerService.update(data);
      if (!response.error) {
        this.$emit("success");
        this.$root.$bvToast.toast("Update brand success", {
          title: `Success`,
          variant: "success",
          solid: true
        });
      }

      this.$root.$bvModal.hide("brand-edit");
      this.isProcessing = false;
    }
  }
};
</script>

<style></style>
