<template>
  <b-modal
    size="md"
    id="brand-create"
    title="Add New Brand"
    ok-title="Save"
    @ok="submit"
    :ok-disabled="isProcessing"
    @hidden="reset"
  >
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="name">Brand Name <span class="text-danger">*</span></label>
      </div>
      <div class="col-sm-9">
        <b-form-input v-model="form.name" id="name"></b-form-input>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="brandColor"
          >Brand Color <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-input
          type="color"
          v-model="form.brandColor"
          id="brandColor"
        ></b-form-input>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="statuses">Statuses </label>
      </div>
      <div class="col-sm-9">
        <b-form-textarea
          @change="onChangeStatus"
          placeholder="Enter something..."
          rows="3"
          v-model="statusesText"
          id="statuses"
        ></b-form-textarea>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="intention"
          >Intention <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-textarea
          @change="onChangeIntention"
          placeholder="Enter something..."
          rows="3"
          v-model="intentionText"
          id="intention"
        ></b-form-textarea>
      </div>
    </div>

    <!-- <div class="form-group row">
      <div class="col-sm-3">
        <label for="mediaList">Media/Influencer</label>
      </div>
      <div class="col-sm-9">
        <multiselect
          v-model="form.mediaList"
          :multiple="true"
          :options="mediaInfluencers"
          :searchable="true"
          :close-on-select="false"
          :show-labels="true"
          :hide-selected="false"
          placeholder="Pick a value"
          :limit="3"
          :options-limit="300"
          track-by="value"
          label="label"
        ></multiselect>
      </div>
    </div> -->

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="start_end"
          >Line Alert Start/End Time <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-input type="time" v-model="form.startTime"></b-form-input>
        <b-form-input type="time" v-model="form.endTime"></b-form-input>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="reportWay"
          >Report Channel <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <multiselect
          :options="lines"
          multiple
          v-model="form.reportWay.lines"
          track-by="value"
          label="text"
          id="reportWay"
        ></multiselect>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="cronjob"
          ><span>Line Notify Time </span><span class="text-danger">*</span
          ><br /><span class="text-muted">Ex. 23:00</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-tags input-id="keywords" v-model="form.cronJob"></b-form-tags>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { CustomerService } from "../../../core/services";

export default {
  name: "BrandCreate",
  props: {
    lines: {
      type: Array,
      default: () => []
    },
    mediaInfluencers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isProcessing: false,
      form: {
        name: null,
        brandColor: "#000000",
        reportWay: {
          lines: []
        },
        statuses: [],
        intention: [],
        startTime: null,
        endTime: null,
        mediaList: [],
        cronJob: []
        // competitors: [],
      },
      // competitorName: "",
      // competitorColor: "#000000",
      intentionText: "",
      statusesText: ""
    };
  },
  validations: {
    form: {
      name: { required },
      brandColor: { required },
      reportWay: {
        lines: {
          required
        }
      },
      // statuses: { required },
      intention: { required },
      startTime: { required },
      endTime: { required },
      cronJob: { required }
    }
  },
  methods: {
    onChangeIntention() {
      this.form.intention = this.intentionText.split(/\n/, -1).filter(v => v);
      this.intentionText = this.intentionText.replace(/\n$/, "");
    },
    onChangeStatus() {
      this.form.statuses = this.statusesText.split(/\n/, -1).filter(v => v);
      this.statusesText = this.statusesText.replace(/\n$/, "");
    },
    async submit(e) {
      e.preventDefault();
      this.isProcessing = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.isProcessing = false;
        return;
      }
      let data = Object.assign({}, this.form);
      data.mediaList = data.mediaList.map(v => v.value);
      let response = await CustomerService.create(data);
      if (!response.error) {
        this.$emit("success");
        this.$root.$bvToast.toast("Create brand success", {
          title: `Success`,
          variant: "success",
          solid: true
        });
        this.$root.$bvModal.hide("brand-create");
      }

      this.isProcessing = false;
    },
    reset() {
      this.form = Object.assign(
        {},
        {
          name: null,
          brandColor: "#000000",
          reportWay: {
            lines: []
          },
          statuses: [],
          intention: [],
          startTime: null,
          endTime: null,
          mediaList: [],
          cronJob: []
        }
      );
      this.intentionText = "";
      this.statusesText = "";
    }
  }
};
</script>

<style></style>
